export default [
  {name: 'Historic Eclipse', lat: -1.46962, lng: 3.60516, zoom: 8},
  {name: 'Weird Life', lat: -7.722280278, lng: 49.96177083, zoom: 11},
  {name: 'Dwarf Stars', lat: -62.71861111, lng: 37.52895292, zoom: 11},
  {name: 'Weighing Galaxy', lat: -19.23309, lng: 15.422128, zoom: 11},
  {name: 'Seeking Jupiters', lat: 38.34731111, lng: 67.72341667, zoom: 11},
  {name: 'Habitable Planet', lat: -7.722280278, lng: 49.96177083, zoom: 11},
  {name: 'Seeking Life', lat: -7.722280278, lng: 49.96177083, zoom: 11},
  {name: 'Star Quake', lat: -20.41097222, lng: 92.26383333, zoom: 11},
  {name: 'Dusty Disks', lat: -76.53694, lng: -12.35208333, zoom: 11},
  {name: 'Tiny Solar System', lat: -76.53694444, lng: -12.35208333, zoom: 11},
  {name: 'Biggest Explosions', lat: 27.72538889, lng: 15.41183333, zoom: 11},
  {name: 'Sun Twin', lat: 69.0085, lng: -4.78125, zoom: 11},
  {name: 'Expanding Sun', lat: 26.41888889, lng: 151.1508333, zoom: 11},
  {name: 'Betelgeuse', lat: 7.407061111, lng: -91.10706125, zoom: 8},
  {name: 'Antares', lat: -26.43194444, lng: 67.45, zoom: 8},
  {name: 'Cygnus X-1', lat: 35.20161111, lng: 119.6904167, zoom: 11},
  {name: 'Starlit Trail', lat: -29.00777778, lng: 86.51683333, zoom: 11},
  {name: 'Space Organics', lat: 12.47441667, lng: -32.27875, zoom: 11},
  {name: 'Copper Origin', lat: -47.47686111, lng: 21.79120833, zoom: 11},
  {name: 'Biggest Stars', lat: -34.19902778, lng: 81.280875, zoom: 11},
  {name: 'Orion Nebula', lat: -5.391111111, lng: -96.07791667, zoom: 11},
  {name: 'Bang!', lat: 28.9825, lng: 9.156958333, zoom: 11},
  {name: 'Milky Center', lat: -29.00777778, lng: 86.51683333, zoom: 11},
  {name: 'Sgr A*', lat: -29.00777778, lng: 86.51683333, zoom: 11},
  {name: 'Pulsar Found', lat: -29.00552778, lng: 86.51583333, zoom: 11},
  {name: 'Solitary Stars?', lat: 7.014444444, lng: -15.77920833, zoom: 11},
  {name: 'Runaway Star', lat: -0.975111111, lng: -122.6914167, zoom: 11},
  {name: 'Vampire Stars', lat: -72.05205556, lng: -173.876375, zoom: 11},
  {name: 'Red Giant Planet', lat: 49.65305556, lng: -138.3083333, zoom: 11},
  {name: 'Inside Quasars', lat: -12.5325, lng: -6.935, zoom: 11},
  {name: 'Why Spiral?', lat: 30.66, lng: -156.4379167, zoom: 11},
  {name: 'Young Galaxy', lat: -27.81108333, lng: -126.7385833, zoom: 11},
  {name: 'Cosmic Telescope', lat: 66.21666667, lng: 69.075, zoom: 11},
  {name: 'Galaxy Clusters', lat: 66.21666667, lng: 69.075, zoom: 11},
  {name: 'Light-Year', lat: 4.982666667, lng: 108.0565, zoom: 11},
  {name: 'Dust Confirmed', lat: 12.69333333, lng: -166.5045833, zoom: 11},
  {name: 'Galaxy Universe', lat: 27.98055556, lng: 15.05416667, zoom: 11},
  {name: 'Gravity Lens', lat: 55.89888889, lng: -29.56375, zoom: 11},
  {name: 'Galaxy Birth', lat: 36.84736111, lng: 48.77958333, zoom: 11},
  {name: 'Stellar Outburst', lat: 41.90908333, lng: -40.56341667, zoom: 11},
  {name: 'First Quasar', lat: 2.0525, lng: 7.377916667, zoom: 11},
  {name: 'Ghostly Ring', lat: 17.16194444, lng: -173.2541667, zoom: 11},
  {name: 'Largest Spiral', lat: 14.33036111, lng: 9.347291667, zoom: 11},
  {name: 'Hubble Ultra Deep', lat: -27.78611111, lng: -126.775, zoom: 11},
  {name: 'Expanding Universe', lat: 41.26916667, lng: -169.2154167, zoom: 11},
  {name: 'Intergalactic Stars', lat: 12.71666667, lng: 6.85, zoom: 11},
  {name: 'Primordial Galaxy', lat: -11.04444444, lng: -173.3541667, zoom: 11},
  {name: 'Colliding Galaxies', lat: -24.80833333, lng: 126.3708333, zoom: 11},
  {name: 'M1', lat: 22.014467, lng: -96.366783},
  {name: 'M2', lat: -0.823194, lng: 143.363167},
  {name: 'M3', lat: 28.376389, lng: 25.547083},
  {name: 'M4', lat: -26.52630600000001, lng: 65.896792},
  {name: 'M5', lat: 2.082417, lng: 49.639583},
  {name: 'M6', lat: -32.253333, lng: 85.083333},
  {name: 'M7', lat: -34.793333, lng: 88.4625},
  {name: 'M8', lat: -24.38, lng: 90.922083},
  {name: 'M9', lat: -18.516167, lng: 79.799042},
  {name: 'M10', lat: -4.098833, lng: 74.288167},
  {name: 'M11', lat: -6.27, lng: 102.770833},
  {name: 'M12', lat: -1.947694, lng: 71.809792},
  {name: 'M13', lat: 36.459805, lng: 70.42175},
  {name: 'M14', lat: -3.24525, lng: 84.400417},
  {name: 'M15', lat: 12.167167, lng: 142.49325},
  {name: 'M16', lat: -13.806667, lng: 94.70000000000002},
  {name: 'M17', lat: -16.171667, lng: 95.195833},
  {name: 'M18', lat: -17.101944, lng: 94.99375},
  {name: 'M19', lat: -26.267694, lng: 75.656875},
  {name: 'M20', lat: -22.971667, lng: 90.67500000000001},
  {name: 'M21', lat: -22.49, lng: 91.05416700000002},
  {name: 'M22', lat: -23.904778, lng: 99.100583},
  {name: 'M23', lat: -18.985, lng: 89.266667},
  {name: 'M24', lat: -18.483, lng: 94.225},
  {name: 'M25', lat: -19.113333, lng: 97.95},
  {name: 'M26', lat: -9.383333, lng: 101.325},
  {name: 'M27', lat: 22.720917, lng: 119.901167},
  {name: 'M28', lat: -24.868472, lng: 96.13645800000002},
  {name: 'M29', lat: 38.508333, lng: 125.9875},
  {name: 'M30', lat: -23.179194, lng: 145.0925},
  {name: 'M31', lat: 41.26890300000001, lng: -169.315421},
  {name: 'M32', lat: 40.865055, lng: -169.325458},
  {name: 'M33', lat: 30.660167, lng: -156.537917},
  {name: 'M34', lat: 42.761667, lng: -139.479167},
  {name: 'M35', lat: 24.35, lng: -87.75000000000001},
  {name: 'M36', lat: 34.14, lng: -95.92500000000001},
  {name: 'M37', lat: 32.553333, lng: -91.925},
  {name: 'M38', lat: 35.848333, lng: -97.833333},
  {name: 'M39', lat: 48.416667, lng: 142.925},
  {name: 'M40', lat: 58.083, lng: 5.552},
  {name: 'M41', lat: -20.756667, lng: -78.495833},
  {name: 'M42', lat: -5.390222, lng: -96.181833},
  {name: 'M43', lat: -5.2675, lng: -96.11941700000001},
  {name: 'M44', lat: 19.666667, lng: -49.9},
  {name: 'M45', lat: 24.11667, lng: -123.15},
  {name: 'M46', lat: -14.81, lng: -64.558333},
  {name: 'M47', lat: -14.483333, lng: -65.854167},
  {name: 'M48', lat: -5.750000000000001, lng: -56.57083300000001},
  {name: 'M49', lat: 8.000278, lng: 7.444917000000001},
  {name: 'M50', lat: -8.383333, lng: -74.325},
  {name: 'M51', lat: 47.19569700000001, lng: 22.468542},
  {name: 'M52', lat: 61.59333300000001, lng: 171.2},
  {name: 'M53', lat: 18.168667, lng: 18.230208},
  {name: 'M54', lat: -30.47958300000001, lng: 103.76325},
  {name: 'M55', lat: -30.962222, lng: 114.999375},
  {name: 'M56', lat: 30.18375, lng: 109.14875},
  {name: 'M57', lat: 33.028917, lng: 103.396292},
  {name: 'M58', lat: 11.818333, lng: 9.431833},
  {name: 'M59', lat: 11.646639, lng: 10.509667},
  {name: 'M60', lat: 11.552222, lng: 10.916542},
  {name: 'M61', lat: 4.473005, lng: 5.478817},
  {name: 'M62', lat: -30.112417, lng: 75.30362500000001},
  {name: 'M63', lat: 42.02944400000001, lng: 18.955083},
  {name: 'M64', lat: 21.682672, lng: 14.181921},
  {name: 'M65', lat: 13.092055, lng: -10.267417},
  {name: 'M66', lat: 12.990694, lng: -9.937500000000002},
  {name: 'M67', lat: 11.8, lng: -47.175},
  {name: 'M68', lat: -26.742139, lng: 9.867292},
  {name: 'M69', lat: -32.347639, lng: 97.845833},
  {name: 'M70', lat: -32.290833, lng: 100.802083},
  {name: 'M71', lat: 18.77825, lng: 118.442375},
  {name: 'M72', lat: -12.537306, lng: 133.365917},
  {name: 'M73', lat: -12.635278, lng: 134.733333},
  {name: 'M74', lat: 15.7835, lng: -155.82575},
  {name: 'M75', lat: -21.921889, lng: 121.520042},
  {name: 'M76', lat: 51.58, lng: -154.408333},
  {name: 'M77', lat: -0.013372, lng: -139.330467},
  {name: 'M78', lat: 0.08, lng: -93.3125},
  {name: 'M79', lat: -24.524805, lng: -98.95583300000001},
  {name: 'M80', lat: -22.9755, lng: 64.261792},
  {name: 'M81', lat: 69.065575, lng: -31.111479},
  {name: 'M82', lat: 69.68063900000001, lng: -31.030333},
  {name: 'M83', lat: -29.86425, lng: 24.251417},
  {name: 'M84', lat: 12.886778, lng: 6.26525},
  {name: 'M85', lat: 18.190808, lng: 6.3506},
  {name: 'M86', lat: 12.946158, lng: 6.549467},
  {name: 'M87', lat: 12.390777, lng: 7.706037},
  {name: 'M88', lat: 14.420083, lng: 7.9965},
  {name: 'M89', lat: 12.556278, lng: 8.916292},
  {name: 'M90', lat: 13.16325, lng: 9.208125},
  {name: 'M91', lat: 14.496389, lng: 8.860458},
  {name: 'M92', lat: 43.13650000000001, lng: 79.280875},
  {name: 'M93', lat: -23.856667, lng: -63.875},
  {name: 'M94', lat: 41.119992, lng: 12.721096},
  {name: 'M95', lat: 11.703555, lng: -19.009417},
  {name: 'M96', lat: 11.820028, lng: -18.309583},
  {name: 'M97', lat: 55.018944, lng: -11.300917},
  {name: 'M98', lat: 14.900206, lng: 3.451125},
  {name: 'M99', lat: 14.416972, lng: 4.706375},
  {name: 'M100', lat: 15.822583, lng: 5.729000000000001},
  {name: 'M101', lat: 54.348917, lng: 30.802083},
  {name: 'M103', lat: 60.65, lng: -156.654167},
  {name: 'M104', lat: -11.62302, lng: 9.997773},
  {name: 'M105', lat: 12.581889, lng: -18.043208},
  {name: 'M106', lat: 47.30443900000001, lng: 4.740042},
  {name: 'M107', lat: -13.053194, lng: 68.133083},
  {name: 'M108', lat: 55.67400000000001, lng: -12.120875},
  {name: 'M109', lat: 53.37463900000001, lng: -0.600333},
  {name: 'M110', lat: 41.685364, lng: -169.907671},
  {name: 'Abell 520', lat: 2.92272, lng: -106.447, zoom: 11},
  {name: 'Abell 3627', lat: -60.7489, lng: 63.3159, zoom: 11},
  {name: 'Cartwheel Galaxy', lat: -33.7119, lng: -170.578, zoom: 13},
  {name: 'Centaurus A', lat: -43.0333, lng: 21.353, zoom: 10},
  {name: 'M82', lat: 69.6802, lng: -31.0418, zoom: 10},
  {name: 'M87', lat: 12.3681, lng: 7.69851, zoom: 10},
  {name: 'Sombrero Galaxy', lat: -11.6277, lng: 9.99286, zoom: 11},
  {name: 'NGC 3079', lat: 55.6804, lng: -29.5101, zoom: 12},
  {name: 'Coronet Cluster', lat: -36.977, lng: 105.447, zoom: 10},
  {name: 'Crescent Nebula', lat: 38.4115, lng: 123.108, zoom: 11},
  {name: 'Eta Carinae Nebula', lat: -59.6847, lng: -18.7354, zoom: 12},
  {name: 'M16', lat: -13.833, lng: 94.7089, zoom: 12},
  {name: 'Ant Nebula', lat: -51.9867, lng: 64.3053, zoom: 13},
  {name: 'Cassiopeia A', lat: 58.8122, lng: 170.862, zoom: 10},
  {name: 'Crab Nebula', lat: 22.0135, lng: -96.37, zoom: 11},
  {name: 'G292.0+1.8', lat: -59.2684, lng: -8.84864, zoom: 10},
  {name: "Kepler's Supernova Remnant", lat: -21.4926, lng: 82.6704, zoom: 12},
  {name: 'N49', lat: -66.0834, lng: -98.4993, zoom: 12},
  {name: 'The Andromeda Galaxy', lat: 41.152284636, lng: -168.802497811, zoom: 7},
  {name: 'IC 1613', lat: 2.06866, lng: -163.854, zoom: 9},
  {name: 'Triangulum Galaxy', lat: 30.605, lng: -156.6, zoom: 9},
  {name: "Barnard's Galaxy", lat: -14.8453, lng: 116.184, zoom: 9},
  {name: 'NGC 3109', lat: -26.1229, lng: -29.1101, zoom: 9},
  {name: 'NGC 55', lat: -39.2433, lng: -176.338, zoom: 9},
  {name: 'NGC 247', lat: -20.8081, lng: -168.268, zoom: 9},
  {name: 'NGC 300', lat: -37.7298, lng: -166.339, zoom: 9},
  {name: 'Sculptor Galaxy', lat: -25.3355, lng: -168.166, zoom: 9},
  {name: 'Whirlpool Galaxy', lat: 47.1948, lng: 22.4506, zoom: 10},
  {name: "Bode's Galaxy and Cigar Galaxy", lat: 69.304, lng: -31.0082, zoom: 9},
  {name: 'Southern Pinwheel Galaxy', lat: -29.8662, lng: 24.2757, zoom: 10},
  {name: 'Messier 101', lat: 54.2961, lng: 30.7171, zoom: 9},
  {name: 'NGC 925', lat: 33.5501, lng: -143.192, zoom: 9},
  {name: 'NGC 1097', lat: -30.3217, lng: -138.477, zoom: 9},
  {name: 'NGC 1365', lat: -36.1828, lng: -126.707, zoom: 9},
  {name: 'NGC 2403', lat: 65.5331, lng: -65.9092, zoom: 9},
  {name: 'NGC 3621', lat: -32.8547, lng: -10.4948, zoom: 9},
  {name: 'NGC 4236', lat: 69.4067, lng: 4.03107, zoom: 9},
  {name: 'NGC 4395', lat: 33.4963, lng: 6.39524, zoom: 9},
  {name: 'M81', lat: 69.0543, lng: -31.1247, zoom: 10},
  {name: 'NGC 4725', lat: 25.4826, lng: 12.5921, zoom: 10},
  {name: 'NGC 1566', lat: -54.9546, lng: -115.029, zoom: 10},
  {name: 'NGC 2976', lat: 67.9004, lng: -33.2298, zoom: 11},
  {name: 'NGC 3351', lat: 11.6975, lng: -19.0155, zoom: 11},
  {name: 'NGC3627', lat: 12.9692, lng: -9.9564, zoom: 10},
  {name: 'NGC 7793', lat: -32.6157, lng: 179.426, zoom: 10},
  {name: 'M51', lat: 47.1947, lng: 22.4606, zoom: 10},
  {name: 'M104', lat: -11.6263, lng: 9.99198, zoom: 11},
  {name: 'M82', lat: 69.677, lng: -31.0406, zoom: 10},
  {name: 'NGC 2207 &amp; IC 2163', lat: -21.3784, lng: -85.9039, zoom: 11},
  {name: 'Snowflake Cluster', lat: 9.59392, lng: -79.7815, zoom: 9},
  {name: 'Henize 206', lat: -71.0673, lng: -97.4316, zoom: 10},
  {name: 'IC 1396', lat: 57.4609, lng: 144.023, zoom: 10},
  {name: 'Trifid Nebula', lat: -22.985, lng: 90.6089, zoom: 10},
  {name: 'NGC 1333', lat: 31.2475, lng: -127.77, zoom: 9},
  {name: 'Orion Nebula', lat: -5.42049, lng: -96.2625, zoom: 8},
  {name: 'Pleiades', lat: 24.1269, lng: -123.1269, zoom: 8},
  {name: 'Rosette Nebula', lat: 4.91839, lng: -81.9329, zoom: 9},
  {name: 'Crab Nebula', lat: 22.0159, lng: -96.372, zoom: 11},
  {name: 'Helix Nebula', lat: -20.8479, lng: 157.399, zoom: 9},
  {name: 'Carina Nebula', lat: -59.5962595334, lng: -18.8779182475, zoom: 9},
  {name: 'AEGIS Survey', lat: 52.8281580169, lng: 34.8223675927, zoom: 10},
  {name: 'Antennae Galaxies', lat: -18.88014431, lng: 0.471098508, zoom: 12},
  {name: 'Super Star Clusters', lat: 23.50258108, lng: 53.738684825, zoom: 13},
  {name: 'Galaxy Seen on Edge', lat: 55.76117753, lng: 46.62968769, zoom: 12},
  {name: 'Five-Star Rated Gravitational Lens', lat: 41.21145649, lng: -28.85808896, zoom: 12},
  {name: 'Open Star Cluster in SMC', lat: -73.1616497966, lng: -167.188497013, zoom: 13},
  {name: 'Star Cluster in SMC', lat: -73.4775618046, lng: -168.201610554, zoom: 13},
  {name: 'Magnificent Starburst Galaxy', lat: 69.68065433, lng: -31.02039676, zoom: 11},
  {name: 'Nearby Dust Clouds', lat: 56.6448604996, lng: -166.733368725, zoom: 12},
  {name: 'Pinwheel Galaxy', lat: 54.349693295, lng: 30.7978632, zoom: 10},
  {name: 'Pinwheel-Shaped Galaxy', lat: -15.3963822745, lng: -129.47618154, zoom: 12},
  {name: 'Great Orion Nebula', lat: -5.41396038333, lng: -96.2094762035, zoom: 9},
  {name: 'Crab Nebula', lat: 22.0152411, lng: -96.3644657, zoom: 11},
  {name: 'Powerful Outflows from Young Stars', lat: -72.1745654078, lng: -165.218837076, zoom: 12},
  {name: 'Afterglow of Stellar Explosion', lat: -69.6414686569, lng: -98.7412559556, zoom: 12},
  {name: 'Ultra Deep Field', lat: -27.7911155797, lng: -126.837975856, zoom: 12},
  {name: 'Boomerang Nebula', lat: -54.5203206605, lng: 11.1926605588, zoom: 13},
  {name: 'Zoo of Galaxies', lat: -27.9433722311, lng: -126.827648955, zoom: 12},
  {name: 'Supernova Remnant Menagerie', lat: -66.0322591064, lng: -96.0673687604, zoom: 12},
  {name: 'Stellar Spire in Eagle Nebula', lat: -13.75653334, lng: 94.81754576, zoom: 11},
  {name: 'Whirlpool Galaxy', lat: 47.212104935, lng: 22.46771414, zoom: 10},
  {name: 'Cosmic Dust Bunnies', lat: -37.2082778, lng: -129.3261044, zoom: 12},
  {name: 'Impending Galaxy Destruction', lat: -35.621262105, lng: -124.95989625, zoom: 12},
  {name: 'Light Echoes', lat: -3.8466086015, lng: -73.978235425, zoom: 12},
  {name: 'Perfect Barred Spiral Galaxy', lat: -19.41154149, lng: -130.07984805, zoom: 12},
  {name: 'Bug Nebula', lat: -37.10423769, lng: 78.443458815, zoom: 13},
  {name: 'Distorted Spiral Galaxies', lat: -21.376192755, lng: -85.89503165, zoom: 12},
  {name: 'Baby Galaxy', lat: 55.241982225, lng: -36.49478101, zoom: 13},
  {name: 'Helix Nebula', lat: -20.8395191, lng: 157.418437, zoom: 10},
  {name: 'Sagittarius Dwarf Galaxy', lat: -17.68110272, lng: 112.4951343, zoom: 12},
  {name: "Kepler's Supernova Remnant", lat: -21.482669215, lng: 82.66727886, zoom: 12},
  {name: 'An Interstellar Geode', lat: -67.9093253, lng: -99.560748755, zoom: 12},
  {name: 'Galaxy Similar to Ours', lat: 47.858505075, lng: -1.57627745, zoom: 12},
  {name: "Single Star's Mass", lat: -70.48791333, lng: -100.82710685, zoom: 13},
  {name: 'Bright Supernova Nearby', lat: 65.6023413, lng: -65.7564591, zoom: 12},
  {name: 'Trifid Nebula', lat: -23.03442197, lng: 90.59161191, zoom: 11},
  {name: 'Ring Galaxy', lat: -74.237295985, lng: -79.17503985, zoom: 12},
  {name: 'Myriad of Stars in Spiral Galaxy', lat: -37.6820907, lng: -166.2743707, zoom: 12},
  {name: 'Supersonic Shock Wave', lat: -69.269349305, lng: -96.13311477, zoom: 13},
  {name: 'Farthest Known Galaxy', lat: 66.20852261, lng: 68.961996885, zoom: 13},
  {name: 'Supernova Bonanza', lat: 64.84879468, lng: -112.29034185, zoom: 13},
  {name: 'Black Eye Galaxy', lat: 21.682587375, lng: 14.180386515, zoom: 12},
  {name: "Galaxy's Fatal Plunge", lat: 66.262214645, lng: 55.29142515, zoom: 13},
  {name: 'Prehistoric Massive Galaxy', lat: -29.455775862, lng: 13.2275985191, zoom: 13},
  {name: 'Firestorm of Star Birth', lat: 30.782139967, lng: -156.365773817, zoom: 12},
  {name: 'Sombrero Galaxy', lat: -11.6226949842, lng: 9.99802978121, zoom: 11},
  {name: 'Home of 1994 Supernova', lat: 17.27738123, lng: -18.221087285, zoom: 12},
  {name: 'Too Close for Comfort', lat: -53.6724812682, lng: 85.1756735166, zoom: 12},
  {name: 'Debris of Stellar Explosion', lat: -66.0808293365, lng: -98.4958260154, zoom: 12},
  {name: 'Pencil Nebula', lat: -45.9162380465, lng: -44.9188204467, zoom: 12},
  {name: "Andromeda's Halo", lat: 40.7100822838, lng: -168.470016333, zoom: 12},
  {name: 'Dusty Spiral Galaxy', lat: 41.5180741138, lng: -130.049571663, zoom: 12},
  {name: 'Egg Nebula', lat: 36.6939944375, lng: 135.578271603, zoom: 13},
  {name: 'Dumbbell Nebula', lat: 22.7215401, lng: 119.8892412, zoom: 11},
  {name: 'Radio Jet From Spiral Galaxy', lat: -19.1103611843, lng: -131.032562366, zoom: 12},
  {name: 'Biggest Zoom Lens in Space', lat: -1.3396143425, lng: 17.87532991, zoom: 12},
  {name: 'Double Bubble of Gas and Dust', lat: -67.461655749, lng: -101.537991743, zoom: 12},
  {name: 'Little Ghost Nebula', lat: -23.75993949, lng: 82.337272495, zoom: 13},
  {name: 'Odd Couple of Galaxies', lat: 75.324578053, lng: 5.45087204562, zoom: 12},
  {name: "Seyfert's Sextet", lat: 20.75763238, lng: 59.80128165, zoom: 12},
  {name: "Hoag's Object", lat: 21.58468357, lng: 49.310324555, zoom: 12},
  {name: "Gomez's Hamburger", lat: -32.180614305, lng: 92.30681474, zoom: 13},
  {name: 'Tiny Galaxy is Born', lat: -11.6104518, lng: 21.4524862, zoom: 13},
  {name: 'Cassiopea A', lat: 58.82790287, lng: 170.86905075, zoom: 12},
  {name: 'Retina Nebula', lat: -44.1508428, lng: 35.6094825, zoom: 12},
  {name: 'Gaseous Streamers', lat: -67.9856480744, lng: -99.4710636256, zoom: 12},
  {name: 'The Mice', lat: 30.72825102, lng: 11.54565394, zoom: 12},
  {name: 'Omega Nebula', lat: -16.15739109, lng: 95.14351352, zoom: 12},
  {name: 'Cone Nebula', lat: 9.421053752, lng: -79.6994363, zoom: 12},
  {name: 'Rich Background near Tadpole', lat: 55.430680345, lng: 61.549754675, zoom: 12},
  {name: "Thackeray's Globules", lat: -63.3437003903, lng: -5.40655602055, zoom: 12},
  {name: 'Giant Star Factory', lat: -14.719292846, lng: 116.220307714, zoom: 13},
  {name: 'Spiral Galaxy in Ultraviolet', lat: -59.9220567939, lng: 110.993048699, zoom: 13},
  {name: 'Ghost Head Nebula', lat: -69.6453580097, lng: -95.0675731144, zoom: 13},
  {name: 'Core of Globular Omega Centauri', lat: -47.481672, lng: 21.7127702, zoom: 12},
  {name: "Bubble in Galaxy's Core", lat: 55.67964684, lng: -29.51227953, zoom: 12},
  {name: 'Bright Double Cluster', lat: -68.7642713442, lng: -102.804780634, zoom: 12},
  {name: 'Warped Edge-On Galaxy', lat: -26.7804184225, lng: 28.7679601203, zoom: 12},
  {name: "Star Clusters in Stephan's Quintet", lat: 33.9680310374, lng: 159.019813472, zoom: 12},
  {name: 'Vast Star-Forming Region', lat: -69.09247259, lng: -95.33527766, zoom: 12},
  {name: 'Multicolor Barred Spiral Galaxy', lat: -43.3487796703, lng: -119.024261537, zoom: 13},
  {name: 'Horsehead Nebula', lat: -2.48084141326, lng: -94.759405045, zoom: 8},
  {name: 'Massive Stars Sculpt Gas', lat: -69.1742490354, lng: -106.37928199, zoom: 12},
  {name: 'Galaxy on the Edge', lat: 43.95381705, lng: -0.353878706, zoom: 12},
  {name: 'Ant Nebula', lat: -51.9859603607, lng: 64.3059014191, zoom: 13},
  {name: 'Another Cosmic Collision', lat: 58.561128075, lng: -7.870910057, zoom: 13},
  {name: 'Tiny, Youthful Spiral Galaxy', lat: -30.2138589909, lng: -127.120520967, zoom: 13},
  {name: 'Star-burst Galaxy Center', lat: 53.4985219, lng: -20.306373905, zoom: 13},
  {name: "Intergalactic 'Pipeline'", lat: -1.300864477, lng: -124.70603585, zoom: 13},
  {name: 'Circinus Galaxy', lat: -65.3382229369, lng: 33.291093108, zoom: 13},
  {name: "Bird's Eye View of Collision", lat: 40.7468847522, lng: 105.419994767, zoom: 13},
  {name: 'Dying Star in Globular', lat: 12.164666605, lng: 142.4920458, zoom: 12},
  {name: 'Crescent Nebula', lat: 38.4537685976, lng: 123.135250029, zoom: 12},
  {name: 'Feasting Black Hole', lat: 12.99615907, lng: 6.9321098205, zoom: 12},
  {name: 'Black Hole-Powered Jet', lat: 12.391560135, lng: 7.701999774, zoom: 12},
  {name: 'Lined-Up Pair of Galaxies', lat: -27.6839870598, lng: -20.6960809422, zoom: 13},
  {name: 'Glowing Eye', lat: -5.99146369814, lng: 106.480794789, zoom: 13},
  {name: 'Eskimo Nebula', lat: 20.9120215716, lng: -67.704720328, zoom: 13},
  {name: 'Bubble Nebula', lat: 61.2096510625, lng: 170.174608827, zoom: 12},
  {name: 'Fireworks of Star Formation', lat: 36.32418326, lng: 3.9091255885, zoom: 11},
  {name: 'Chaotic Winds', lat: -18.208597355, lng: -64.519531485, zoom: 13},
  {name: 'Fast Stellar Wind', lat: -0.8066924105, lng: -72.65704415, zoom: 13},
  {name: 'Young Star Ejects Material', lat: 11.03158532, lng: 110.12937755, zoom: 13},
  {name: 'Swarm of Ancient Stars', lat: -22.97519139, lng: 64.26090513, zoom: 12},
  {name: 'Polar Ring Galaxy', lat: -40.715861365, lng: 11.20688629, zoom: 12},
  {name: 'Famous Ring Nebula', lat: 33.028827635, lng: 103.39565805, zoom: 12},
  {name: 'Glowing Pool of Light', lat: -40.43501544, lng: -28.242730225, zoom: 13},
  {name: 'Sagittarius Star Cloud', lat: -29.204026485, lng: 89.746128745, zoom: 12},
  {name: 'Black Hole', lat: 10.76668907, lng: 176.0653797, zoom: 12},
  {name: 'Bright Ring of Star Birth', lat: 29.89481781, lng: 5.632841654, zoom: 12},
  {name: 'Gamma-Ray Burst', lat: 65.19899583, lng: -0.890057247, zoom: 13},
  {name: 'Massive Black Hole', lat: -43.016620785, lng: 21.372288135, zoom: 12},
  {name: 'Center of Barred Spiral', lat: -37.51045505, lng: -103.0668826, zoom: 12},
  {name: 'Silkworm Nebula', lat: -24.214622985, lng: 86.807108645, zoom: 13},
  {name: 'Spiral-Structured Planetary', lat: -51.205844175, lng: 27.763802175, zoom: 13},
  {name: 'Balloon Shaped Planetary', lat: -57.18254957, lng: -2.42489013, zoom: 13},
  {name: 'Eye-Shaped Planetary', lat: 50.525407125, lng: 116.2007231, zoom: 13},
  {name: 'Butterfly Nebula', lat: -10.142301339, lng: 76.4082624951, zoom: 13},
  {name: 'Distant Mileposts', lat: 13.259511085, lng: 10.72304598, zoom: 12},
  {name: 'Hubble Deep Field', lat: 62.215940415, lng: 9.198554487, zoom: 12},
  {name: 'Pillars of Creation', lat: -13.8309599, lng: 94.71114683, zoom: 12},
  {name: 'Fire and Fury of Stellar Birth', lat: -6.462326635, lng: -96.123277655, zoom: 12},
  {name: 'Mosaic of Distant Galaxies', lat: 28.2502700068, lng: 14.9957512307, zoom: 12},
  {name: 'Cartwheel Galaxy', lat: -33.71030129, lng: -170.5762627, zoom: 12},
  {name: "Cat's Eye Nebula", lat: 66.632658535, lng: 89.64016942, zoom: 13},
  {name: 'Primeval galaxies', lat: 21.427565335, lng: 57.45341556, zoom: 13},
  {name: 'Grand Design Spiral Galaxy', lat: 15.821277875, lng: 5.7353238735, zoom: 12},
  {name: 'Mystery of Quasars', lat: 20.15762363, lng: 8.0149662835, zoom: 13},
];
